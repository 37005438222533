import React from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import Navbar from './Navbar';
import Footer from './Footer';


export default function Courses() {
  return (
    <>
    <Helmet>
  <title>Insights & Innovation Blog | Advisions Research and Development</title>
  <meta
    name="description"
    content="Stay updated with the latest trends and insights in AI, quantum computing, defense technologies, and telecommunications. Explore thought leadership and expert articles from Advisions Research and Development Private Limited."
  />

  <meta
    name="keywords"
    content="Advisions blog, AI blog, technology insights, defense R&D blog, quantum computing articles, telecommunications innovations, artificial intelligence trends, machine learning insights, cybersecurity updates, quantum technologies blog, 5G and telecom articles, advanced R&D blogs, Advisions innovation blog, tech trends, AI-powered solutions blog, future technology discussions, expert insights, research and development articles, innovation updates, thought leadership, Advisions posts, cutting-edge technology blog"
  />

  <meta name="author" content="Advisions Research and Development Private Limited (Advisions)" />
  <meta property="og:title" content="Insights & Innovation Blog | Advisions Research and Development" />
  <meta
    property="og:description"
    content="Explore expert perspectives and thought leadership in AI, telecommunications, defense, and quantum computing on the Advisions Research and Development blog."
  />
  <meta property="og:url" content="https://www.advisionslab.com/blogs" />
  <meta property="og:type" content="website" />
  <meta property="og:image" content="https://www.advisionslab.com/images/blog-banner.jpg" />

  {/* Twitter Card Metadata */}
  <meta name="twitter:card" content="summary_large_image" />
  <meta name="twitter:title" content="Insights & Innovation Blog | Advisions Research and Development" />
  <meta
    name="twitter:description"
    content="Discover the latest trends and expert insights in AI, telecommunications, defense technologies, and quantum computing on our blog."
  />
  <meta name="twitter:image" content="https://www.advisionslab.com/images/blog-banner.jpg" />

  <link rel="canonical" href="https://www.advisionslab.com/blogs" />
</Helmet>

      <Navbar />

      <div className="page-title dark-background">
  <div className="container position-relative">
    <h1>Our Blogs</h1>
    <p className="lead my-3">
      Stay updated with the latest insights, innovations, and breakthroughs in AI research and development, as we share our expertise and knowledge across various industries.
    </p>
  </div>
</div>


      <section id="portfolio" className="portfolio section-bg mt-4 mb-4">
        <div className="container">
    
          <div className="row portfolio-container">
            {/* Robotic Automation */}
            <div className="col-lg-4 col-md-6 portfolio-item filter-web">
              <div className="portfolio-wrap">
                <img src="assets/img/project-1.jpg" className="img-fluid" alt="Robotic Automation"/>
                <div className="portfolio-info">
                  <h4>Robotic Automation</h4>
                  <p>Coming Soon</p>
                  <div className="portfolio-links">
                    <Link to="/contact" className="venobox" title="Robotic Automation"><i className="icofont-eye"></i></Link>
                    <Link to="/contact" title="More Details"><i className="icofont-external-link"></i></Link>
                  </div>
                </div>
              </div>
            </div>

            {/* Machine Learning */}
            <div className="col-lg-4 col-md-6 portfolio-item filter-app">
              <div className="portfolio-wrap">
                <img src="assets/img/project-2.jpg" className="img-fluid" alt="Machine Learning"/>
                <div className="portfolio-info">
                  <h4>Machine Learning</h4>
                  <p>Coming Soon</p>
                  <div className="portfolio-links">
                    <Link to="/contact" className="venobox" title="Machine Learning"><i className="icofont-eye"></i></Link>
                    <Link to="/contact" title="More Details"><i className="icofont-external-link"></i></Link>
                  </div>
                </div>
              </div>
            </div>

            {/* Data Analysis */}
            <div className="col-lg-4 col-md-6 portfolio-item filter-card">
              <div className="portfolio-wrap">
                <img src="assets/img/project-3.jpg" className="img-fluid" alt="Data Analysis"/>
                <div className="portfolio-info">
                  <h4>Data Analysis</h4>
                  <p>Coming Soon</p>
                  <div className="portfolio-links">
                    <Link to="/contact" className="venobox" title="Data Analysis"><i className="icofont-eye"></i></Link>
                    <Link to="/contact" title="More Details"><i className="icofont-external-link"></i></Link>
                  </div>
                </div>
              </div>
            </div>

            {/* FullStack Web Development */}
            <div className="col-lg-4 col-md-6 portfolio-item filter-web">
              <div className="portfolio-wrap">
                <img src="assets/img/project-1.jpg" className="img-fluid" alt="FullStack Web Development"/>
                <div className="portfolio-info">
                  <h4>FullStack Web Development</h4>
                  <p>Coming Soon</p>
                  <div className="portfolio-links">
                    <Link to="/contact" className="venobox" title="FullStack Web Development"><i className="icofont-eye"></i></Link>
                    <Link to="/contact" title="More Details"><i className="icofont-external-link"></i></Link>
                  </div>
                </div>
              </div>
            </div>

            {/* VLSI */}
            <div className="col-lg-4 col-md-6 portfolio-item filter-app">
              <div className="portfolio-wrap">
                <img src="assets/img/project-2.jpg" className="img-fluid" alt="VLSI"/>
                <div className="portfolio-info">
                  <h4>VLSI</h4>
                  <p>Coming Soon</p>
                  <div className="portfolio-links">
                    <Link to="/contact" className="venobox" title="VLSI"><i className="icofont-eye"></i></Link>
                    <Link to="/contact" title="More Details"><i className="icofont-external-link"></i></Link>
                  </div>
                </div>
              </div>
            </div>

            {/* Artificial Intelligence */}
            <div className="col-lg-4 col-md-6 portfolio-item filter-card">
              <div className="portfolio-wrap">
                <img src="assets/img/bgg1.jpg" className="img-fluid" alt="Microprocessor"/>
                <div className="portfolio-info">
                  <h4>Artificial Intelligence</h4>
                  <p>Coming Soon</p>
                  <div className="portfolio-links">
                    <Link to="/contact" className="venobox" title="Microprocessor"><i className="icofont-eye"></i></Link>
                    <Link to="/contact" title="More Details"><i className="icofont-external-link"></i></Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
}
