import React, { useState } from 'react';
import Navbar from './Navbar';
import Footer from './Footer';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import { Link } from 'react-router-dom';

const SchoolERPSystem = () => {
  const [showModal, setShowModal] = useState(false);

  // Function to toggle modal display
  const handleModalToggle = () => {
    setShowModal(!showModal);
  };

  return (
    <>
      <Navbar />
      <section id="about" className="about">
        <div className="container">
          <div className="row no-gutters d-flex align-items-stretch">
            <div className="col-lg-5 video-box d-flex align-items-stretch">
              <img
                src="assets/img/SchoolErp.png"
                className="img-fluid"
                alt="School ERP System"
                style={{ objectFit: 'cover', height: '100%', width: '100%' }}
              />
              <a
                href="https://youtu.be/Y4qjgXNtbhU?si=Mpn6iceKC915x-08"
                className="venobox play-btn mb-4"
                data-vbtype="video"
                data-autoplay="true"
              ></a>
            </div>

            <div className="col-lg-7 d-flex flex-column justify-content-center about-content">
              <div className="section-title mt-2">
                <h2>About Our School ERP System</h2>
                <a href="https://school.advisionslab.com/" className="try-now-link m-4" role="button">
                  Try Now <i className="fas fa-arrow-right ms-2"></i>
                </a>
                <p className="mt-4">
                  Our School ERP System is specifically designed for Indian schools, providing an all-in-one solution for managing academic and administrative tasks. The platform streamlines daily operations, integrates communication channels, and enhances efficiency across all school departments.
                </p>
              </div>

              <div className="icon-box">
                <div className="icon">
                  <i className="bx bx-chalkboard"></i>
                </div>
                <h4 className="title">
                  <a href="#">Academic Management</a>
                </h4>
                <p className="description">
                  Manage classes, subjects, exams, and report cards seamlessly. Our ERP system offers real-time access to academic records, attendance tracking, and performance analytics.
                </p>
              </div>

              <div className="icon-box">
                <div className="icon">
                  <i className="bx bx-user-check"></i>
                </div>
                <h4 className="title">
                  <a href="#">Student Information System</a>
                </h4>
                <p className="description">
                  Keep student data organized and up-to-date, including personal details, academic records, and attendance. Our system provides a centralized database for easy access by teachers and administrators.
                </p>
              </div>

              <div className="icon-box">
                <div className="icon">
                  <i className="bx bx-money"></i>
                </div>
                <h4 className="title">
                  <a href="#">Fee Management</a>
                </h4>
                <p className="description">
                  Simplify the fee collection process with automated invoicing, online payments, and detailed financial reports. Parents can pay fees easily, and schools can track payments in real time.
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="container mt-5">
          <div className="row d-flex">
            <div className="col-lg-9 mt-4 d-flex align-items-stretch">
              <div className="card w-100">
                <div className="card-body">
                  <ul className="nav nav-tabs" id="myTab" role="tablist">
                    <li className="nav-item" role="presentation">
                      <a
                        className="nav-link active"
                        id="specifications-tab"
                        data-bs-toggle="tab"
                        href="#specifications"
                        role="tab"
                        aria-controls="specifications"
                        aria-selected="true"
                      >
                        Specifications
                      </a>
                    </li>
                    <li className="nav-item" role="presentation">
                      <a
                        className="nav-link"
                        id="information-tab"
                        data-bs-toggle="tab"
                        href="#information"
                        role="tab"
                        aria-controls="information"
                        aria-selected="false"
                      >
                        Information
                      </a>
                    </li>
                    <li className="nav-item" role="presentation">
                      <a
                        className="nav-link"
                        id="pricing-tab"
                        data-bs-toggle="tab"
                        href="#pricing"
                        role="tab"
                        aria-controls="pricing"
                        aria-selected="false"
                      >
                        Pricing Info
                      </a>
                    </li>
                  </ul>

                  <div className="tab-content" id="myTabContent">
                    {/* Specifications Tab Content */}
                    <div
                      className="tab-pane fade show active mt-4"
                      id="specifications"
                      role="tabpanel"
                      aria-labelledby="specifications-tab"
                    >
                      <p>
                        Our School ERP System is equipped with comprehensive features that cater to all academic and administrative needs. It integrates seamlessly with existing school processes and provides tools to manage students, staff, fees, and more.
                      </p>
                      <p>
                        Key features include student enrollment management, attendance tracking, grade reporting, and parent-teacher communication channels. The system also supports timetable scheduling, library management, and transport tracking.
                      </p>
                      <p>
                        Designed to adapt to the unique requirements of Indian schools, the ERP system is customizable and scalable, making it suitable for schools of all sizes, from small private institutions to large public schools.
                      </p>
                      {/* Read More Button */}
                      <button
                        type="button"
                        className="btn btn-primary mt-3"
                        onClick={handleModalToggle}
                      >
                        Read More
                      </button>
                    </div>

                    {/* Modal Structure */}
                    {showModal && (
                      <div
                        className="modal fade show"
                        tabIndex="-1"
                        style={{ display: 'block' }}
                        aria-labelledby="readMoreModalLabel"
                        aria-modal="true"
                        role="dialog"
                      >
                        <div className="modal-dialog modal-lg">
                          <div className="modal-content">
                            <div className="modal-header">
                              <h5 className="modal-title" id="readMoreModalLabel">
                                Comprehensive Proposal for Smart School Management ERP System
                              </h5>
                              <button
                                type="button"
                                className="btn-close"
                                onClick={handleModalToggle}
                                aria-label="Close"
                              ></button>
                            </div>
                            <div className="modal-body">
                              {/* Modal Content */}
                              <p>
                                Advisions Research and Development Private Limited is pleased to present its innovative Smart School Management ERP System. Designed with advanced technology, our systems aim to revolutionize the educational landscape in India by addressing the unique needs of both urban and rural schools. This detailed proposal outlines the features and benefits of our ERP, demonstrating their potential to support the Indian government's educational initiatives and drive significant improvements in educational outcomes.
                              </p>
                              <h6>ERP System Features:</h6>
                              <ul>
                                <li><strong>Centralize management of academic and administrative data:</strong> The system will act as a central repository for all school-related data, making it easier to manage and access information.</li>
                                <li><strong>Facilitate efficient and effective communication:</strong> The system provides tools for seamless communication between teachers, students, parents, and administrators.</li>
                                <li><strong>Provide tools for managing academic activities:</strong> Includes managing timetables, exams, assignments, and other academic-related activities.</li>
                                <li><strong>Enhance the management of school operations:</strong> Streamlines tasks such as tracking attendance, managing fees, and organizing transport.</li>
                                <li><strong>Offer a scalable solution:</strong> The system is designed to grow with the school's needs, supporting future expansion and additional functionalities.</li>
                              </ul>
                              {/* Additional Modal Content */}
                              <h6>System Features Overview:</h6>
                              <ul>
                                <li><strong>Profile Management:</strong> Manage profiles for students, parents, teachers, principals, and administrators, providing access to relevant data and communication tools.</li>
                                <li><strong>Role and User Management:</strong> Define and manage user roles with appropriate access levels and maintain security within the system.</li>
                                <li><strong>Class, Section, and Medium Management:</strong> Organizes students and teachers into classes and sections and manages different mediums of instruction.</li>
                                <li><strong>Attendance, Timetable, and Exam Management:</strong> Tracks attendance, creates timetables, manages exam schedules and grading, and handles assignments and announcements.</li>
                                <li><strong>Fee and Financial Management:</strong> Tracks and manages school fees, different fee types, payment tracking, and financial reporting.</li>
                                <li><strong>Communication Management:</strong> Real-time notifications, parent-teacher interactions, and administrative announcements to enhance communication within the school community.</li>
                                <li><strong>Data Security:</strong> User permissions, encryption, regular backups, and compliance with data protection regulations ensure data privacy and security.</li>
                                <li><strong>Scalability and Customization:</strong> Modular architecture, cloud-based infrastructure, and customization options to support evolving needs.</li>
                                <li><strong>Additional Features:</strong> Management of holidays, online exams, branch operations, blog content, permissions, events, competitions, and more.</li>
                              </ul>
                              <h6>Implementation Plan:</h6>
                              <ul>
                                <li><strong>Phase 1: Needs Assessment:</strong> A detailed analysis of the school's specific requirements to customize the system accordingly.</li>
                                <li><strong>Phase 2: Training and Deployment:</strong> Comprehensive training sessions for staff and faculty, followed by staged deployment.</li>
                                <li><strong>Phase 3: Ongoing Support and Monitoring:</strong> Continuous technical support and system monitoring, with user feedback for adjustments.</li>
                              </ul>
                              <h6>Benefits to Schools:</h6>
                              <ul>
                                <li><strong>Improved Efficiency:</strong> Automates repetitive tasks, centralizes data management, and reduces errors, enhancing school operations.</li>
                                <li><strong>Enhanced Communication:</strong> Provides real-time notifications, facilitates parent-teacher interaction, and ensures effective administrative announcements.</li>
                                <li><strong>Data Security:</strong> Robust security features protect sensitive data, ensuring compliance with data protection regulations.</li>
                                <li><strong>Scalability:</strong> Supports growth with modular design, cloud-based infrastructure, and customizable features.</li>
                              </ul>
                              <h6>Conclusion:</h6>
                              <p>
                                Advisions Research and Development Private Limited is committed to providing a comprehensive and scalable School Management System tailored to the needs of schools. The proposal highlights the system’s ability to enhance school operations, leading to improved academic and administrative efficiency.
                              </p>
                              <h6>Contact Information:</h6>
                              <p>
                                For further discussion and to proceed with the implementation, please contact us at:
                              </p>
                              <p>
                                <strong>Advisions Research and Development Private Limited</strong><br />
                                Software Technology Parks of India<br />
                                Ministry of Electronics and Information Technology || GOI<br />
                                Noida, Uttar Pradesh, 201301, India<br />
                                Email: <a href="mailto:info@advisionslab.com">info@advisionslab.com</a><br />
                                Website: <a href="https://www.advisionslab.com">www.advisionslab.com</a><br />
                                Phone Number: +91 8810316395
                              </p>
                            </div>
                            <div className="modal-footer">
                              <button
                                type="button"
                                className="btn btn-secondary"
                                onClick={handleModalToggle}
                              >
                                Close
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}

                    {/* Information Tab Content */}
                    <div
                      className="tab-pane fade mt-4"
                      id="information"
                      role="tabpanel"
                      aria-labelledby="information-tab"
                    >
                      <p>
                        Our ERP system emphasizes ease of use, ensuring that all school staff can operate it without extensive training. The user-friendly interface and intuitive design make it accessible for teachers, students, and parents alike.
                      </p>
                      <p>
                        With powerful analytics, the ERP system provides insights into student performance, teacher effectiveness, and school operations, helping administrators make data-driven decisions to improve educational outcomes.
                      </p>
                      <p>
                        The system ensures data privacy and security, adhering to the highest standards of data protection, so schools can rest assured that sensitive information remains safe and confidential.
                      </p>
                    </div>

                    {/* Pricing Info Tab Content */}
                    <div
                      className="tab-pane fade mt-4"
                      id="pricing"
                      role="tabpanel"
                      aria-labelledby="pricing-tab"
                    >
                      <p>
                        The School ERP System is available on a flexible subscription model, with plans tailored to the needs of different types of schools. Pricing options include monthly, yearly, and lifetime subscriptions with various features and support levels.
                      </p>
                      <p>
                        We offer discounts for government schools, non-profits, and educational institutions serving underprivileged communities. All subscriptions include free onboarding, training, and 24/7 customer support.
                      </p>
                      <p>
                        Schools can also opt for additional customization services to adapt the system to specific requirements, such as integrating third-party apps or adding unique modules.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-3 mt-4 d-flex align-items-stretch">
              <div className="card w-100">
                <div className="card-header text-center bg-primary text-white">
                  <h5>Pricing Plan</h5>
                </div>
                <div className="card-body">
                  <h6 className="card-title">Monthly / Yearly Subscription</h6>
                  <p className="card-text">
                    Access our complete ERP suite for <strong>per students needs</strong>. This plan includes all academic management features, student information system, fee management, and more.
                  </p>
                  <h6 className="card-title">Additional Benefits</h6>
                  <ul>
                    <li>Free Data Migration</li>
                    <li>Dedicated onboarding support</li>
                    <li>Regular software updates</li>
                  </ul>
                </div>
                <div className="card-footer text-center">
                  <Link to="/contact" className="btn btn-primary">
                    Subscribe Now
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default SchoolERPSystem;
