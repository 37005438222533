import React, { useState, useEffect } from "react";
import Sidebar from './SideBar';
import axios from "axios";
import { Box, Grid, Typography, Table, TableBody, TableCell, TableRow, Button, Link, Accordion, AccordionSummary, AccordionDetails, TableHead } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import EditChannelModal from './EditChannelForm';  // Import the modal component


const Student = () => {

  const [data, setData] = useState([]);
  const [blogs, setBlogs] = useState([]);
  const [National, setNational] = useState([]);
  const [International, setInternational] = useState([]);
  const [companies, setCompanies] = useState(0); 
  const [students, setStudents] = useState(0); 
  const [blogCount, setBlogCount] = useState(0);

  const axiosInstance = axios.create({ baseURL: process.env.REACT_APP_API_URL });

  const userList = async () => {
    try {
      const user = await axiosInstance.get("/users/student", {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("auth_token"),
        },
      });
      setData(user.data.studentInfo);
      setStudents(user.data.studentInfo.length); 
    } catch (error) {
      console.error("Error fetching student data:", error);
    }
  };

  const fetchBlogs = async () => {
    try {
      const response = await axiosInstance.get("/blogsall");
      setBlogs(response.data);
      setBlogCount(response.data.length); 
    } catch (error) {
      console.error("Error fetching blogs:", error);
    }
  };

  const fetchChannels = async () => {
    try {
      const response = await axiosInstance.get("/channels");
      setCompanies(response.data.length); 
    } catch (error) {
      console.error("Error fetching channels:", error);
    }
  };

  useEffect(() => {
    const fetchBlogs = async () => {
      try {
        const { data } = await axiosInstance.get('/channelsAll');
        setBlogs(data);
        console.log(data);
        const nationalPartners = data.filter(partner => partner.role === 'National').length;
        const internationalPartners = data.filter(partner => partner.role === 'International').length;
        setNational(nationalPartners);
        setInternational(internationalPartners);
        console.log(`Number of National Partners: ${nationalPartners}`);
        console.log(`Number of International Partners: ${internationalPartners}`);
      } catch (error) {
        console.error('Error fetching blogs:', error);
      }
    };
  
    fetchBlogs();
  }, []);


  useEffect(() => {
    userList();
    fetchBlogs();
    fetchChannels();
  }, []);
  const [userData, setUserData] = useState(null);
  const [channels, setChannels] = useState([]);
  const [selectedChannel, setSelectedChannel] = useState(null); // To track the selected channel for editing
  const [isEditModalOpen, setIsEditModalOpen] = useState(false); // Modal open/close state

  useEffect(() => {
    const userDataFromStorage = localStorage.getItem('user');
    if (userDataFromStorage) {
      try {
        const parsedData = JSON.parse(userDataFromStorage);
        setUserData(parsedData);
      } catch (error) {
        console.error('Failed to parse user data:', error);
      }
    }
  }, []);

  useEffect(() => {
    const fetchChannels = async () => {
      try {
        const response = await axiosInstance.get('/channels');
        setChannels(response.data);
      } catch (error) {
        console.error('Error fetching channels:', error);
      }
    };

    fetchChannels();
  }, []);

  const handleEditClick = (channel) => {
    setSelectedChannel(channel);
    setIsEditModalOpen(true); // Open the modal
  };

  const handleModalClose = () => {
    setIsEditModalOpen(false);
    setSelectedChannel(null);
  };

  const refreshChannels = async () => {
    try {
      const response = await axiosInstance.get('/channels');
      setChannels(response.data); // Refresh the channel list after editing
    } catch (error) {
      console.error('Error refreshing channels:', error);
    }
  };

  const [expandedChannelId, setExpandedChannelId] = useState(null);

  // Toggle accordion
  const handleAccordionToggle = (channelId) => {
    setExpandedChannelId(expandedChannelId === channelId ? null : channelId);
  };

  return (
    <div>
      <div className="app-container app-theme-white body-tabs-shadow fixed-sidebar fixed-header" id="appContent">
        <div className="app-main">
          <Sidebar />
          <div className="col mt-4 app-content">
            <div className="app-main-inner">
            <div className="row ">
            {/* Dashboard Cards */}
            <div className="col-md-6 col-xl-4 mb-3">
              <div className="card widget-content bg-midnight-bloom">
                <div className="widget-content-wrapper text-white">
                  <div className="widget-content-left">
                    <div className="widget-heading">Companies</div>
                    <div className="widget-subheading">Number of total companies</div>
                  </div>
                  <div className="widget-content-right">
                    <div className="widget-numbers text-white">
                      <span style={{ margin: "60px" }}>{companies}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-xl-4 mb-3">
              <div className="card widget-content bg-arielle-smile">
                <div className="widget-content-wrapper text-white">
                  <div className="widget-content-left">
                    <div className="widget-heading">Students</div>
                    <div className="widget-subheading">Number of total students</div>
                  </div>
                  <div className="widget-content-right">
                    <div className="widget-numbers text-white">
                      <span style={{ margin: "60px" }}>{students}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-xl-4 mb-3">
              <div className="card widget-content bg-grow-early">
                <div className="widget-content-wrapper text-white">
                  <div className="widget-content-left">
                    <div className="widget-heading">Blogs</div>
                    <div className="widget-subheading">Number of total blogs created</div>
                  </div>
                  <div className="widget-content-right">
                    <div className="widget-numbers text-white">
                      <span style={{ margin: "60px" }}>{blogCount}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-xl-4 mb-3">
            <div className="card widget-content bg-plum-plate">
                <div className="widget-content-wrapper text-white">
                  <div className="widget-content-left">
                    <div className="widget-heading">National Partner</div>
                    <div className="widget-subheading">Total National Partners</div>
                  </div>
                  <div className="widget-content-right">
                    <div className="widget-numbers text-white">
                      <span style={{ margin: "60px" }}>{National}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-xl-4 mb-3">
            <div className="card widget-content bg-night-fade">
                <div className="widget-content-wrapper text-white">
                  <div className="widget-content-left">
                    <div className="widget-heading">International Partners</div>
                    <div className="widget-subheading"> Total International Partners</div>
                  </div>
                  <div className="widget-content-right">
                    <div className="widget-numbers text-white">
                      <span style={{ margin: "60px" }}>{International}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-xl-4 mb-3">
            <div className="card widget-content bg-love-kiss">
                <div className="widget-content-wrapper text-white">
                  <div className="widget-content-left">
                    <div className="widget-heading">Reviews</div>
                    <div className="widget-subheading">Total submitted reviews</div>
                  </div>
                  <div className="widget-content-right">
                    <div className="widget-numbers text-white">
                      <span style={{ margin: "60px" }}>0</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row mt-4" id="deleteTableItem">
      <div className="col-md-12">
        <div className="card mb-5">
          <div className="card-body">
            <Box sx={{ p: 4 }}>
              <Table>
                {/* Table Heading */}
                <TableHead>
  <TableRow >
  <TableCell>
    <Typography fontWeight="bold">Channel Image</Typography>
    </TableCell>
    <TableCell>
      <Typography fontWeight="bold">Channel Name</Typography>
    </TableCell>
    <TableCell >
      <Typography  fontWeight="bold">Channel Address</Typography>
    </TableCell>
    <TableCell >
      <Typography fontWeight="bold">Channel Email</Typography>
    </TableCell>
    <TableCell >
      <Typography fontWeight="bold">Channel Phone</Typography>
    </TableCell>
    <TableCell >
      <Typography fontWeight="bold">Channel Info</Typography>
    </TableCell>
    <TableCell >
      <Typography fontWeight="bold">Actions</Typography>
    </TableCell>
  </TableRow>
</TableHead>

                <TableBody>
                  {channels.map((channel) => (
                    channel.channel_name === userData?.userName && (
                      <React.Fragment key={channel._id}>
                        <TableRow>
                        <TableCell>
                            <Box
                              component="img"
                              src={channel.channel_image_file}
                              alt={channel.channel_name}
                              sx={{ width: '100px', height: '100px', objectFit: 'cover', borderRadius: '8px' }}
                            />
                          </TableCell>
                          <TableCell>{channel.channel_name}</TableCell>
                          <TableCell>{channel.channel_address}</TableCell>
                          <TableCell>{channel.channel_email}</TableCell>
                          <TableCell>{channel.channel_phone}</TableCell>
                          <TableCell>{channel.channel_info}</TableCell>
                      
                          <TableCell align="center">
  <Button
    variant="contained"
    onClick={() => handleEditClick(channel)}
    sx={{
      bgcolor: 'primary.main',
      color: 'white',
      fontWeight: 'bold',
      textDecoration: 'none',
      marginRight: 2,
      '&:hover': {
        bgcolor: 'primary.dark', // Darker shade on hover
      },
    }}
  >
    Edit
  </Button>
  
  <Button
    variant="outlined"
    onClick={() => handleAccordionToggle(channel._id)}
    sx={{
      color: 'primary.main',
      fontWeight: 'bold',
      textDecoration: 'none',
      '&:hover': {
        bgcolor: 'primary.light', // Light background on hover
      },
    }}
  >
    View Contact 
  </Button>
</TableCell>

                        </TableRow>

                        {/* Accordion for Contact Details */}
                        {expandedChannelId === channel._id && (
                          <TableRow>
                            <TableCell colSpan={7}>
                              <Accordion expanded={true}>
                                <AccordionDetails>
                                  <Table>
                                    <TableHead>
                                      <TableRow>
                                        <TableCell><Typography fontWeight="bold">Contact Name</Typography></TableCell>
                                        <TableCell><Typography fontWeight="bold">Contact Phone</Typography></TableCell>
                                        <TableCell><Typography fontWeight="bold">Contact Email</Typography></TableCell>
                                        <TableCell><Typography fontWeight="bold">Id Proof</Typography></TableCell>
                                      </TableRow>
                                    </TableHead>
                                    <TableBody>
                                      <TableRow>
                                        <TableCell>{channel.contact_name}</TableCell>
                                        <TableCell>{channel.contact_phone}</TableCell>
                                        <TableCell>{channel.contact_email}</TableCell>
                                        <TableCell>
                            <Link href={channel.channel_address_file} target="_blank" rel="noopener">
                              View File
                            </Link>
                          </TableCell>
                                      </TableRow>
                                    </TableBody>
                                  </Table>
                                </AccordionDetails>
                              </Accordion>
                            </TableCell>
                          </TableRow>
                        )}
                      </React.Fragment>
                    )
                  ))}
                </TableBody>
              </Table>
            </Box>
          </div>
        </div>
      </div>
    </div>
            </div>
          </div>
        </div>
      </div>

      {/* Add the Edit Modal */}
      {selectedChannel && (
        <EditChannelModal
          open={isEditModalOpen}
          handleClose={handleModalClose}
          channel={selectedChannel}
          onUpdate={refreshChannels} // Call to refresh channels after editing
        />
      )}
    </div>
  );
};

export default Student;
