import React from "react";
import Navbar from "./Navbar";
import Footer from "./Footer";
import { Helmet } from "react-helmet";

const TeamMember = ({ name, role, description, image, socials }) => {
  return (
    <div className="col-12 col-md-6 col-lg-4 d-flex">
      <div className="card border-0 shadow-lg pt-2 my-5 w-100 d-flex flex-column">
        <div className="card-body p-4 d-flex flex-column">
          
          <div className="card-text pt-4 flex-grow-1">
            <h5 className="member-name mb-0 text-center text-primary font-weight-bold">{name}</h5>
            <div className="mb-3 text-center">{role}</div>
            <div className="text-justify">{description}</div>
          </div>
        </div>
        <div className="card-footer theme-bg-primary border-0 text-center mt-auto">
          <ul className="social-list list-inline mb-0 mx-auto">
            {socials.map((social, index) => (
              <li key={index} className="list-inline-item">
                <a className="text-dark" href={social.link}>
                  <i className={`fab fa-${social.platform} fa-fw`}></i>
                </a>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

const TeamSection = () => {
  const teamMembers = [
    {
      name: "Mr. Mayank Verma",
      role: "19+ years of experience in Telecommunication",
      description: "Mayank Verma is a seasoned telecommunications expert with nearly two decades of experience. He has led major projects across the industry, specializing in network architecture, system optimization, and the deployment of next-generation communication technologies. Known for his strategic vision, he has consistently driven operational excellence and innovation in diverse environments.",
      image: "https://bootdey.com/img/Content/avatar/avatar7.png",
      socials: [
        { platform: "linkedin-in", link: "#" },
        { platform: "twitter", link: "#" },
      ],
    },
    {
      name: "Dr. Singh",
      role: "Defence Industry Expert",
      description: "With over 19 years of dedicated service in the Defence Industry, Dr. Singh has been instrumental in the development of cutting-edge technologies for national security. His expertise spans defense systems engineering, strategic operations, and policy advisory roles. A visionary leader, he has contributed significantly to modernizing defense capabilities.",
      image: "https://bootdey.com/img/Content/avatar/avatar1.png",
      socials: [
        { platform: "linkedin-in", link: "#" },
      ],
    },
    {
      name: "Dr. R Aggarwal",
      role: "Entrepreneurship",
      description: "Dr. R Aggarwal brings over 30 years of entrepreneurial experience, having founded and scaled multiple successful ventures. His deep understanding of business strategy, market analysis, and mentorship has empowered numerous startups to achieve sustainable growth. As a thought leader, he actively promotes innovation and entrepreneurship in emerging industries.",
      image: "https://bootdey.com/img/Content/avatar/avatar8.png",
      socials: [
        { platform: "linkedin-in", link: "#" },
      ],
    },
    {
      name: "Professor Prakash Dwivedi",
      role: "NIT Uttarakhand",
      description: "Prakash Dwivedi, an accomplished scholar from NIT Uttarakhand, has consistently excelled in engineering and collaborative innovation. His expertise in modern engineering practices and passion for problem-solving make him a vital contributor to technical advancements and interdisciplinary projects.",
      image: "https://bootdey.com/img/Content/avatar/avatar2.png",
      socials: [
        { platform: "linkedin-in", link: "#" },
      ],
    },
    {
      name: "Professor Vivek Srivastava",
      role: "Electrical & Electronics Expert",
      description: "Dr. Vivek Srivastava is a veteran in the Electrical and Electronics domain with over 20 years of academic and industrial experience. His pioneering work in circuit design, power systems, and renewable energy solutions has earned him accolades in research and innovation. He is committed to fostering new talent in engineering.",
      image: "https://bootdey.com/img/Content/avatar/avatar3.png",
      socials: [
        { platform: "linkedin-in", link: "#" },
      ],
    },
    {
      name: "Dr. Sachin Tiwari",
      role: "Assistant Professor, ECE Department, MIT Pune",
      description: "Dr. Sachin Tiwari is an esteemed academic with profound expertise in Electronics and Communication Engineering. At MIT Pune, he has been pivotal in advancing research in wireless communication and signal processing. His dedication to student mentorship and curriculum development sets him apart as a dynamic educator.",
      image: "https://bootdey.com/img/Content/avatar/avatar4.png",
      socials: [
        { platform: "linkedin-in", link: "#" },
      ],
    },
    {
      name: "Professor Angshuman Karmakar",
      role: "IIT Kanpur ",
      description: "A promising talent from IIT Kanpur, Professor Angshuman Karmakar is known for his innovative approach to engineering challenges. His contributions to research in sustainable technology and collaborative projects underscore his commitment to shaping a better future through technology.",
      image: "https://bootdey.com/img/Content/avatar/avatar5.png",
      socials: [
        { platform: "linkedin-in", link: "#" },
      ],
    },
  ];

  return (
    <div>
        <Helmet>
  <title>Meet the Team | Advisions Research and Development Private Limited</title>
  <meta
    name="description"
    content="Explore the team driving innovation at Advisions Research and Development Private Limited. Meet our experts in AI, quantum technologies, telecommunications, and defense R&D, whose expertise shapes the future of advanced technology solutions."
  />

  <meta
    name="keywords"
    content="Advisions team, Advisions members, AI experts, quantum computing specialists, defense R&D team, telecommunications engineers, Advisions leadership, Advisions Research and Development team, technology experts, artificial intelligence researchers, machine learning engineers, cybersecurity experts, quantum scientists, innovative thinkers, Advisions staff, technology innovators, R&D professionals, AI-driven innovation team, team of engineers, future technology leaders, Advisions specialists"
  />

  <meta name="author" content="Advisions Research and Development Private Limited (Advisions)" />
  <meta property="og:title" content="Meet the Team | Advisions Research and Development Private Limited" />
  <meta
    property="og:description"
    content="Get to know the innovative minds behind Advisions Research and Development Private Limited. Meet our experts in AI, telecommunications, quantum technologies, and defense innovation."
  />
  <meta property="og:url" content="https://www.advisionslab.com/allmembers" />
  <meta property="og:type" content="website" />

  {/* Twitter Card Metadata */}
  <meta name="twitter:card" content="summary_large_image" />
  <meta name="twitter:title" content="Meet the Team | Advisions Research and Development Private Limited" />
  <meta
    name="twitter:description"
    content="Meet the experts at Advisions driving AI, quantum, defense, and telecom innovations for global impact."
  />
  <meta name="twitter:image" content="https://www.advisionslab.com/images/team-banner.jpg" />

  <link rel="canonical" href="https://www.advisionslab.com/allmembers" />
</Helmet>

      <Navbar />
      <section className="team-section py-5">
        <div className="container">
          <div className="row justify-content-center">
            {teamMembers.map((member, index) => (
              <TeamMember
                key={index}
                name={member.name}
                role={member.role}
                description={member.description}
                image={member.image}
                socials={member.socials}
              />
            ))}
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default TeamSection;
