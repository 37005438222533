import React, { useState } from "react";
import { Modal, TextField, Button, Box, Grid, Typography, CircularProgress } from "@mui/material";
import axios from "axios";
import { CloudUpload } from "@mui/icons-material";

const EditChannelModal = ({ open, handleClose, channel, onUpdate }) => {
  const [formData, setFormData] = useState({
    channel_name: channel?.channel_name || "",
    channel_address: channel?.channel_address || "",
    channel_email: channel?.channel_email || "",
    channel_phone: channel?.channel_phone || "",
    contact_name: channel?.contact_name || "",
    contact_phone: channel?.contact_phone || "",
    contact_email: channel?.contact_email || "",
  });

  const [channelImage, setChannelImage] = useState(null); // State for the new image file
  const [channelDocument, setChannelDocument] = useState(null); // State for the new document file
  const [fileMessage, setFileMessage] = useState(""); // Message when a file is uploaded
  const [loading, setLoading] = useState(false); // Loading state for spinner
  const axiosInstance = axios.create({baseURL : process.env.REACT_APP_API_URL});

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  // Handle file changes
  const handleFileChange = (e) => {
    const { name, files } = e.target;
    if (name === "channel_image") {
      setChannelImage(files[0]);
      setFileMessage("Image uploaded"); // Show confirmation
    }
    if (name === "channel_document") {
      setChannelDocument(files[0]);
      setFileMessage("Document uploaded"); // Show confirmation
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true); // Start spinner on submit

    // Prepare form data to send including files
    const data = new FormData();
    Object.keys(formData).forEach((key) => {
      data.append(key, formData[key]);
    });

    if (channelImage) data.append("channel_image", channelImage);
    if (channelDocument) data.append("channel_document", channelDocument);

    try {
      await axiosInstance.put(`/channels/${channel._id}`, data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      onUpdate(); // Refresh the channels list after the update
      setLoading(false); // Stop spinner
      handleClose();
    } catch (error) {
      console.error("Error updating channel:", error);
      setLoading(false); // Stop spinner even on error
    }
  };

  return (
    <Modal open={open} onClose={handleClose}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 700,
          bgcolor: "background.paper",
          p: 4,
          borderRadius: 2,
        }}
      >
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            {/* Form Fields */}
            <Grid item xs={12}>
              <TextField
                label="Channel Name"
                name="channel_name"
                value={formData.channel_name}
                onChange={handleChange}
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Channel Address"
                name="channel_address"
                value={formData.channel_address}
                onChange={handleChange}
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Channel Email"
                name="channel_email"
                value={formData.channel_email}
                onChange={handleChange}
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Channel Phone"
                name="channel_phone"
                value={formData.channel_phone}
                onChange={handleChange}
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Contact Name"
                name="contact_name"
                value={formData.contact_name}
                onChange={handleChange}
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Contact Phone"
                name="contact_phone"
                value={formData.contact_phone}
                onChange={handleChange}
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Contact Email"
                name="contact_email"
                value={formData.contact_email}
                onChange={handleChange}
                fullWidth
              />
            </Grid>

            {/* File Upload for Image */}
            <Grid item xs={12}>
              <Typography variant="h6" gutterBottom>
                Update Channel Image
              </Typography>
              <Box
                sx={{
                  border: "2px dashed #ccc",
                  borderRadius: 2,
                  padding: 2,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  cursor: "pointer",
                  "&:hover": {
                    borderColor: "#888",
                  },
                }}
                onClick={() => document.getElementById("channel_image_input").click()} // Trigger click on the hidden input
              >
                <input
                  type="file"
                  id="channel_image_input"
                  name="channel_image"
                  onChange={handleFileChange}
                  accept="image/*"
                  style={{ display: "none" }} // Hide the default file input
                />
                <CloudUpload sx={{ marginRight: 1 }} />
                <Typography variant="body1">Drag and drop or click to upload an image</Typography>
              </Box>
              {channelImage && <Typography color="primary">{fileMessage}</Typography>}
            </Grid>

            {/* File Upload for Document */}
            <Grid item xs={12}>
              <Typography variant="h6" gutterBottom>
                Update Channel Document
              </Typography>
              <Box
                sx={{
                  border: "2px dashed #ccc",
                  borderRadius: 2,
                  padding: 2,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  cursor: "pointer",
                  "&:hover": {
                    borderColor: "#888",
                  },
                }}
                onClick={() => document.getElementById("channel_document_input").click()} // Trigger click on the hidden input
              >
                <input
                  type="file"
                  id="channel_document_input"
                  name="channel_document"
                  onChange={handleFileChange}
                  accept="application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                  style={{ display: "none" }} // Hide the default file input
                />
                <CloudUpload sx={{ marginRight: 1 }} />
                <Typography variant="body1">Drag and drop or click to upload a document</Typography>
              </Box>
              {channelDocument && <Typography color="primary">{fileMessage}</Typography>}
            </Grid>

            {/* Spinner and Submit Button */}
            <Grid item xs={12}>
              {loading ? (
                <Box sx={{ display: "flex", justifyContent: "center" }}>
                  <CircularProgress />
                </Box>
              ) : (
                <Button type="submit" variant="contained" color="primary" fullWidth>
                  Save Changes
                </Button>
              )}
            </Grid>
          </Grid>
        </form>
      </Box>
    </Modal>
  );
};

export default EditChannelModal;
