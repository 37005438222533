import React, { useEffect, useState } from 'react';
import axios from 'axios';
import {
  Box,
  Link,
  Button,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MainSidebar from "../AdminDashboard/SidebarAdmin/SidebarAdmin";
import 'bootstrap/dist/css/bootstrap.min.css';

const CompanyDetails = () => {
  const [channels, setChannels] = useState([]);
  const [members, setMembers] = useState({}); // Store members keyed by channelId
  const [openAccordion, setOpenAccordion] = useState(null); // Track which accordion is open
  const axiosInstance = axios.create({ baseURL: process.env.REACT_APP_API_URL });

  useEffect(() => {
    const fetchChannels = async () => {
      try {
        const response = await axiosInstance.get('/channels');
        setChannels(response.data);
      } catch (error) {
        console.error('Error fetching channels:', error);
      }
    };

    fetchChannels();
  }, []);

  const handleViewMembersClick = async (channelId) => {
    if (openAccordion === channelId) {
      // If the clicked accordion is already open, close it
      setOpenAccordion(null);
    } else {
      // Open the clicked accordion and fetch members if not already fetched
      setOpenAccordion(channelId);
      if (!members[channelId]) {
        try {
          const response = await axiosInstance.get(`/members/${channelId}`);
          setMembers((prev) => ({ ...prev, [channelId]: response.data })); // Store members for the specific channel
        } catch (error) {
          console.error('Error fetching members:', error);
        }
      }
    }
  };

  return (
    <div>
      <div className="app-container app-theme-white body-tabs-shadow fixed-sidebar fixed-header" id="appContent">
        <div className="app-main">
          <MainSidebar />
          <div className="col mt-4 app-content">
            <div className="row px-3">
              <div className="page-title-actions px-3 d-flex">
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item"><a href="#">Dashboard</a></li>
                    <li className="breadcrumb-item"><a href="#">Instructor</a></li>
                    <li className="breadcrumb-item active" aria-current="page">Create</li>
                  </ol>
                </nav>
              </div>
              <div className="row mt-2" id="deleteTableItem">
                <div className="col-md-12">
                  <div className="card mb-5">
                    <div className="card-body">
                      <Box sx={{ width: '100%', overflow: 'scroll' }}>
                        <div className="table-responsive-lg text-center">
                          <table id="dataTable" className="table">
                            <thead>
                              <tr className='text-center'>
                                <th>Image</th>
                                <th>Name</th>
                                <th>Address</th>
                                <th>Email</th>
                                <th>Phone</th>
                                <th>Info</th>
                                <th>Contact Name</th>
                                <th>Contact Phone</th>
                                <th>Contact Email</th>
                                <th>Address File</th>
                                <th>Actions</th>
                              </tr>
                            </thead>
                            <tbody>
                              {channels.map((channel) => (
                                <React.Fragment key={channel._id}>
                                  <tr className="align-middle">
                                    <td className="text-center">
                                      <img
                                        src={channel.channel_image_file}
                                        alt={channel.channel_name}
                                        style={{ width: 80, height: 'auto', borderRadius: '5px' }}
                                      />
                                    </td>
                                    <td className="text-justified">{channel.channel_name}</td>
                                    <td className="text-justified">{channel.channel_address}</td>
                                    <td className="text-justified">{channel.channel_email}</td>
                                    <td className="text-justified">{channel.channel_phone}</td>
                                    <td className="text-justified">{channel.channel_info}</td>
                                    <td className="text-justified">{channel.contact_name}</td>
                                    <td className="text-justified">{channel.contact_phone}</td>
                                    <td className="text-justified">{channel.contact_email}</td>
                                    <td className="text-justified">
                                      {channel.channel_address_file ? (
                                        <Link
                                          href={channel.channel_address_file}
                                          target="_blank"
                                          rel="noopener noreferrer"
                                        >
                                          View File
                                        </Link>
                                      ) : (
                                        <Typography variant="body2">No file uploaded</Typography>
                                      )}
                                    </td>
                                    <td>
                                      <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={() => handleViewMembersClick(channel._id)} // Load members and toggle accordion
                                      >
                                        {openAccordion === channel._id ? 'Hide Members' : 'View Members'}
                                      </Button>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td colSpan="11" style={{ padding: 0 }}>
                                      <Accordion expanded={openAccordion === channel._id}>
                                        <AccordionSummary
                                          expandIcon={<ExpandMoreIcon />}
                                          aria-controls={`members-panel-${channel._id}-content`}
                                          id={`members-panel-${channel._id}-header`}
                                        >

                                        </AccordionSummary>
                                        <AccordionDetails>
                                          <table className="table">
                                            <thead>
                                              <tr>
                                                <th>Name</th>
                                                <th>Email</th>
                                                <th>Role</th>
                                                <th>Phone Number</th>
                                                <th>Address</th>
                                                <th>Company</th>

                                              </tr>
                                            </thead>
                                            <tbody>
                                              {members[channel._id]?.map((member) => (
                                                <tr key={member._id}>
                                                  <td>{member.name}</td>
                                                  <td>{member.email}</td>
                                                  <td>{member.role}</td>
                                                  <td>{member.phoneNumber}</td>
                                                  <td>{member.address}</td>
                                                  <td>{member.company}</td>

                                                </tr>
                                              ))}
                                              {members[channel._id]?.length === 0 && (
                                                <tr>
                                                  <td colSpan="3">No members found</td>
                                                </tr>
                                              )}
                                            </tbody>
                                          </table>
                                        </AccordionDetails>
                                      </Accordion>
                                    </td>
                                  </tr>
                                </React.Fragment>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      </Box>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CompanyDetails;
