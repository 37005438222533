import React, { useState, useEffect } from 'react';
import { TextField, Button, Container, Typography, Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import axios from 'axios';
import Sidebar from './SideBar';

const AddMembers = () => {
  const [channels, setChannels] = useState([]);
  const [members, setMembers] = useState([]);
  const [userData, setUserData] = useState(null);
  const [isMembersVisible, setIsMembersVisible] = useState(false); // State to toggle members visibility
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    role: '',
    phoneNumber: '',
    address: '',
    company: '',
    channelId: ''
  });

  const axiosInstance = axios.create({ baseURL: process.env.REACT_APP_API_URL });

  // Load user data from localStorage
  useEffect(() => {
    const userDataFromStorage = localStorage.getItem('user');
    if (userDataFromStorage) {
      try {
        const parsedData = JSON.parse(userDataFromStorage);
        setUserData(parsedData);
      } catch (error) {
        console.error('Failed to parse user data:', error);
      }
    }
  }, []);

  // Fetch channels and members from the API
  useEffect(() => {
    const fetchChannels = async () => {
      try {
        const response = await axiosInstance.get('/channels');
        setChannels(response.data);
      } catch (error) {
        console.error('Error fetching channels:', error);
      }
    };

    const fetchMembers = async () => {
      try {
        const response = await axiosInstance.get('/members');
        setMembers(response.data);
      } catch (error) {
        console.error('Error fetching members:', error);
      }
    };

    fetchChannels();
    fetchMembers();
  }, []);

  // Automatically find and assign the channelId based on the userName
  useEffect(() => {
    if (userData && channels.length > 0) {
      const foundChannel = channels.find(channel => channel.channel_name === userData.userName)?._id;
      setFormData(prevFormData => ({ ...prevFormData, channelId: foundChannel || '' }));
    }
  }, [userData, channels]);

  // Handle form field changes
  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await axiosInstance.post('/members/', formData);
      alert('Member added successfully');
      setFormData({
        name: '',
        email: '',
        role: '',
        phoneNumber: '',
        address: '',
        company: '',
        channelId: ''
      });
      // Refresh member list after adding a new member
      const response = await axiosInstance.get('/members');
      setMembers(response.data);
    } catch (error) {
      console.error('Error adding member:', error);
      alert('Error adding member');
    }
  };

  // Toggle members table visibility
  const toggleMembersVisibility = () => {
    setIsMembersVisible(prev => !prev);
  };

  return (
    <div className="app-container app-theme-white body-tabs-shadow fixed-sidebar fixed-header" id="appContent">
      <div className="app-main">
        <Sidebar />
        <div className="col mt-4 app-content">
          <div className="row px-3">
            <div className="page-title-actions px-3 d-flex">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item"><a href="#">Dashboard</a></li>
                  <li className="breadcrumb-item"><a href="#">Instructor</a></li>
                  <li className="breadcrumb-item active" aria-current="page">Create</li>
                </ol>
              </nav>
            </div>
            <div className="row" id="deleteTableItem">
              <div className="col-md-12">
                <div className="main-card card d-flex h-100 flex-column">
                  <Container maxWidth="xl" className='mt-4 mb-4'>
                    <Typography variant="h4" gutterBottom>
                      Add New Member
                    </Typography>
                    <form onSubmit={handleSubmit}>
                      <Grid container spacing={2}>
                        {/* Form Fields */}
                        <Grid item xs={12} sm={6}>
                          <TextField
                            label="Name"
                            name="name"
                            fullWidth
                            required
                            value={formData.name}
                            onChange={handleChange}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <TextField
                            label="Email"
                            name="email"
                            type="email"
                            fullWidth
                            required
                            value={formData.email}
                            onChange={handleChange}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <TextField
                            label="Role"
                            name="role"
                            fullWidth
                            required
                            value={formData.role}
                            onChange={handleChange}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <TextField
                            label="Phone Number"
                            name="phoneNumber"
                            fullWidth
                            required
                            value={formData.phoneNumber}
                            onChange={handleChange}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <TextField
                            label="Address"
                            name="address"
                            fullWidth
                            required
                            value={formData.address}
                            onChange={handleChange}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <TextField
                            label="Company"
                            name="company"
                            fullWidth
                            required
                            value={formData.company}
                            onChange={handleChange}
                          />
                        </Grid>
                        <Grid item xs={12}>
  <TextField
    select
    label="Channel"
    name="channelId"
    fullWidth
    required
    value={formData.channelId || ''}
    onChange={handleChange}
    SelectProps={{
      native: true,
    }}
  >
    <option value="" disabled>
      Select a Channel
    </option>
    {channels.map((channel) => (
      <option key={channel._id} value={channel._id}>
        {channel.channel_name}
      </option>
    ))}
  </TextField>
</Grid>

                        <Grid item xs={12}>
                          <Button type="submit" variant="contained" color="primary">
                            Add Member
                          </Button>
                        </Grid>
                      </Grid>
                    </form>

                    {/* Toggle View Members Button */}
                    <Button
                      variant="outlined"
                      color="secondary"
                      onClick={toggleMembersVisibility}
                      className="mt-4"
                    >
                      {isMembersVisible ? 'Hide Members' : 'View Members'}
                    </Button>

                    {/* Conditionally render the members table */}
                    {isMembersVisible && (
                      <>

                        <TableContainer component={Paper} >
                          <Table className='mt-4'>
                            <TableHead>
                              <TableRow>
                                <TableCell>Name</TableCell>
                                <TableCell>Email</TableCell>
                                <TableCell>Role</TableCell>
                                <TableCell>Phone Number</TableCell>
                                <TableCell>Address</TableCell>
                                <TableCell>Company</TableCell>
                                <TableCell>Channel ID</TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {members.map((member) => (
                                <TableRow key={member._id}>
                                  <TableCell>{member.name}</TableCell>
                                  <TableCell>{member.email}</TableCell>
                                  <TableCell>{member.role}</TableCell>
                                  <TableCell>{member.phoneNumber}</TableCell>
                                  <TableCell>{member.address}</TableCell>
                                  <TableCell>{member.company}</TableCell>
                                  <TableCell>{member.channelId}</TableCell>
                                </TableRow>
                              ))}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </>
                    )}
                  </Container>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddMembers;
