import React, { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import "./Sidebar.css";
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import Box from '@mui/material/Box';
import GavelIcon from '@mui/icons-material/Gavel';
import PolicyIcon from '@mui/icons-material/Policy';
import DescriptionIcon from '@mui/icons-material/Description';
import AssignmentIcon from '@mui/icons-material/Assignment';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';


const Sidebar = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [userData, setUserData] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);

  useEffect(() => {
    const userDataFromStorage = localStorage.getItem('user');
    if (userDataFromStorage) {
      setUserData(JSON.parse(userDataFromStorage));
    }
  }, []);

  const handleLogout = () => {
    localStorage.clear("user");
    localStorage.clear("auth_token");
    dispatch({ type: "CLEAR__USER" });
    navigate("/login");
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const iconMapping = {
    '/privacyPolicy': <PolicyIcon />,
    '/masterservices': <DescriptionIcon />,
    '/advisionbusinessPrivacy': <PolicyIcon />,
    '/instructorterms': <GavelIcon />,
    '/affiliateterms': <AssignmentIcon />,
    '/termsofuse': <GavelIcon />,
    '/pricingpromotional': <DescriptionIcon />,
    '/advisionsproterms': <AssignmentIcon />,
  };

  return (
    <div className="app-container app-theme-white body-tabs-shadow fixed-sidebar fixed-header" id="appContent">
      <div className="app-header header-shadow">
        <div className="app-header-logo">
          {/* Add Home Link next to logo */}
         
        </div>
        <div className="app-header-content">
        <div className="app-header-left d-flex align-items-center">
        <NavLink to="/" className="nav-link-custom home-link font-weight-medium" style={{fontSize : "22px"}}>
            Home
          </NavLink>
          </div>
          <div className="app-header-right d-flex align-items-center">
            <Typography variant="h6" noWrap component="div" sx={{ display: 'flex', alignItems: 'center' }}>
              {userData ? (
                <div>
                  <IconButton
                    onClick={handleClick}
                    sx={{ color: '#007bff', fontSize: '18px', display: 'flex', alignItems: 'center', marginLeft: '16px' }}
                  >
                    <Typography variant="body1" sx={{ marginRight: '8px' }}>
                      {userData.userName}
                    </Typography>
                    <AccountCircleIcon sx={{ fontSize: '24px' }} />
                  </IconButton>
                  <Menu
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                    PaperProps={{
                      sx: {
                        minWidth: 90,
                      },
                    }}
                  >
                    <MenuItem onClick={handleLogout}>Logout</MenuItem>
                  </Menu>
                </div>
              ) : (
                <Link to="/login" className="nav-link-custom" style={{ marginLeft: '16px' }}>
                  Login
                </Link>
              )}
            </Typography>
          </div>
        </div>
      </div>

      <div className="app-sidebar sidebar-shadow">
        <div className="scrollbar-sidebar">
          <Box sx={{ display: 'flex', justifyContent: 'center', p: 2 }}>
            <img src="logo10.png" alt="Logo" style={{ height: '35px' }} />
          </Box>
          <div className="app-sidebar-inner mt-4">
            <div className="option">
              <NavLink to="/privacyPolicy" activeClassName="active-link" className="nav-link-custom">
                {iconMapping['/privacyPolicy']}
                &nbsp; Privacy And Policy
              </NavLink>
            </div>

            <div className="option">
              <NavLink to="/masterservices" activeClassName="active-link" className="nav-link-custom">
                {iconMapping['/masterservices']}
                &nbsp; Master Services Agreement
              </NavLink>
            </div>

            <div className="option">
              <NavLink to="/advisionbusinessPrivacy" activeClassName="active-link" className="nav-link-custom">
                {iconMapping['/advisionbusinessPrivacy']}
                &nbsp; Advisions Business Privacy Statement
              </NavLink>
            </div>

            <div className="option">
              <NavLink to="/instructorterms" activeClassName="active-link" className="nav-link-custom">
                {iconMapping['/instructorterms']}
                &nbsp; Instructor Terms
              </NavLink>
            </div>

            <div className="option">
              <NavLink to="/affiliateterms" activeClassName="active-link" className="nav-link-custom">
                {iconMapping['/affiliateterms']}
                &nbsp; Affiliate Terms & Conditions
              </NavLink>
            </div>

            <div className="option">
              <NavLink to="/termsofuse" activeClassName="active-link" className="nav-link-custom">
                {iconMapping['/termsofuse']}
                &nbsp; Terms of Use
              </NavLink>
            </div>

            <div className="option">
              <NavLink to="/pricingpromotional" activeClassName="active-link" className="nav-link-custom">
                {iconMapping['/pricingpromotional']}
                &nbsp; Pricing & Promotions Policy
              </NavLink>
            </div>

            <div className="option">
              <NavLink to="/advisionsproterms" activeClassName="active-link" className="nav-link-custom">
                {iconMapping['/advisionsproterms']}
                &nbsp; Advisions Business Pro Terms & Conditions
              </NavLink>
            </div>

          </div>
        </div>
      </div>
    </div>
  );
}

export default Sidebar;
