import React,{useState} from 'react';
import Navbar from './Navbar';
import Footer from './Footer';

export default function Home() {
  const [activeIndex, setActiveIndex] = useState(null);

  const faqs = [
    {
      question: "What industries does Advisions R&D serve?",
      answer:
        "Advisions R&D specializes in providing AI-driven solutions for telecommunications, renewable energy, hardware design, and data engineering, transforming these industries with innovative technology.",
    },
    {
      question: "How does Advisions R&D ensure quality and reliability?",
      answer:
        "We adhere to strict quality standards, including ISO certification, and leverage advanced AI models to ensure our solutions are both reliable and efficient.",
    },
    {
      question: "What makes Advisions R&D different from competitors?",
      answer:
        "Our unique approach combines expertise in AI, VLSI, and hardware design with a deep commitment to sustainability and innovation, giving us a competitive edge.",
    },
    {
      question: "How can I collaborate with Advisions R&D?",
      answer:
        "We are open to partnerships and collaborations. Feel free to reach out via our 'Connect Now' button to explore synergies.",
    },
    {
      question: "What certifications does Advisions R&D hold?",
      answer:
        "We are certified under ISO standards, recognized by MSME, Startup India, and Make in India, and registered as an OEM on GeM.",
    },
  ];

  const toggleFAQ = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };
  return (
    <>
      <Navbar />
      <div className="page-title dark-background" data-aos="fade">
  <div className="container position-relative">
    <h1>About Us</h1>
    <p className="lead my-3">
      Advisions R&D: Pioneering AI solutions to transform industries and drive innovation.
    </p>
  </div>
</div>

     
<section class="py-3 py-md-5">
  <div class="container">
    <div class="row gy-3 gy-md-4 gy-lg-0 align-items-lg-center">
      <div class="col-6 col-lg-5">
        <img class="img-fluid rounded" loading="lazy" src="./assets/img/blog-3.jpg" alt="About 2"/>
      </div>
      <div class="col-12 col-lg-7">
        <div class="row justify-content-xl-center">
          <div class="col-12 col-xl-10">
            <h2 class="mb-3 mt-3">Why Choose Us?</h2>
            <p class="lead fs-5 mb-3 mb-xl-5">With years of experience and deep industry knowledge, we have a proven track record of success and are constantly pushing ourselves to stay ahead of the curve.</p>
            <div class="d-flex align-items-center mb-3">
              <div class="me-3 text-primary">
                <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" fill="currentColor" class="bi bi-check-circle-fill" viewBox="0 0 16 16">
                  <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
                </svg>
              </div>
              <div>
                <p class="fs-6 m-0">Our evolution procedure is super intelligent.</p>
              </div>
            </div>
            <div class="d-flex align-items-center mb-3">
              <div class="me-3 text-primary">
                <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" fill="currentColor" class="bi bi-check-circle-fill" viewBox="0 0 16 16">
                  <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
                </svg>
              </div>
              <div>
                <p class="fs-6 m-0">We deliver services beyond expectations.</p>
              </div>
            </div>
            <div class="d-flex align-items-center mb-4 mb-xl-5">
              <div class="me-3 text-primary">
                <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" fill="currentColor" class="bi bi-check-circle-fill" viewBox="0 0 16 16">
                  <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
                </svg>
              </div>
              <div>
                <p class="fs-6 m-0">Let's hire us to reach your objectives.</p>
              </div>
            </div>
            <button type="button" class="btn bsb-btn-xl btn-outline-primary rounded-pill">Connect Now</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>



<section id="call-to-action" className="call-to-action section dark-background">
  <div className="container">
    <img src="assets/img/RoboticAutomation.png" alt="AI Innovation" />
    <div className="content row justify-content-center">
      <div className="col-xl-10">
        <div className="text-center">
          <a href="https://www.youtube.com/watch?v=Y4qjgXNtbhU" className="glightbox pulsating-play-btn"></a>
          <h3>Transforming Industries with AI Innovation</h3>
          <p>At Advisions R&D, we’re driving the future of AI. Join us in creating cutting-edge solutions that will revolutionize industries, enhance operational efficiency, and foster sustainable growth through artificial intelligence.</p>
          <a className="cta-btn" href="/services">Explore Our Solutions</a>
        </div>
      </div>
    </div>
  </div>
</section>


<section className="team-section py-5">
<div class="container section-title">
<h2>Our Core Team</h2>
<p>Meet the experts behind Advisions R&D, driving innovation and excellence through their expertise in AI, telecommunications, renewable energy, hardware design, and data engineering.</p>
</div>
  <div className="container">
    <div className="row justify-content-center">
      
      {/* Team Member: Mayank Verma */}
      <div className="col-12 col-md-6 col-lg-6 d-flex">
        <div className="card border-0 shadow-lg pt-2 my-5 w-100 d-flex flex-column">
          <div className="card-body p-4 d-flex flex-column">
            <div className="card-text pt-4 flex-grow-1">
              <h5 className="member-name mb-0 text-center text-primary font-weight-bold">M Verma</h5>
              <div className="mb-3 text-center">Director & CEO (Telecommunication Expert)</div>
              <div className="text-justify">
                A seasoned professional with 19+ years of experience in telecommunications, specializing in building innovative communication systems and improving network efficiency.
              </div>
            </div>
          </div>
          <div className="card-footer theme-bg-primary border-0 text-center mt-auto">
            <ul className="social-list list-inline mb-0 mx-auto">
            <li className="list-inline-item">
                <a className="text-dark">
                  <i className={`fab fa-linkedin-in fa-fw`}></i>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>

      {/* Team Member: Santosh Kumar Singh */}
      <div className="col-12 col-md-6 col-lg-6 d-flex">
        <div className="card border-0 shadow-lg pt-2 my-5 w-100 d-flex flex-column">
          <div className="card-body p-4 d-flex flex-column">
            <div className="card-text pt-4 flex-grow-1">
              <h5 className="member-name mb-0 text-center text-primary font-weight-bold">Santosh Kumar Singh</h5>
              <div className="mb-3 text-center">Director & CTO (VLSI & AI/ML Specialist)</div>
              <div className="text-justify">
                An expert with 9+ years of experience in renewable energy, VLSI design, and AI/ML, dedicated to driving sustainable and intelligent energy solutions.
              </div>
            </div>
          </div>
          <div className="card-footer theme-bg-primary border-0 text-center mt-auto">
            <ul className="social-list list-inline mb-0 mx-auto">
            <li className="list-inline-item">
                <a className="text-dark">
                  <i className={`fab fa-linkedin-in fa-fw`}></i>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>

      {/* Team Member: Sandeep Malhotra */}
      <div className="col-12 col-md-6 col-lg-6 d-flex">
        <div className="card border-0 shadow-lg pt-2 my-5 w-100 d-flex flex-column">
          <div className="card-body p-4 d-flex flex-column">
            <div className="card-text pt-4 flex-grow-1">
              <h5 className="member-name mb-0 text-center text-primary font-weight-bold">Sandeep Malhotra</h5>
              <div className="mb-3 text-center">Director (Hardware and AI Specialist)</div>
              <div className="text-justify">
                With 6+ years of experience, he excels in hardware design, data science, and AI, bridging the gap between hardware and intelligent software systems.
              </div>
            </div>
          </div>
          <div className="card-footer theme-bg-primary border-0 text-center mt-auto">
            <ul className="social-list list-inline mb-0 mx-auto">
            <li className="list-inline-item">
                <a className="text-dark">
                  <i className={`fab fa-linkedin-in fa-fw`}></i>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>

      {/* Team Member: Kuldeep Singh */}
      <div className="col-12 col-md-6 col-lg-6 d-flex">
        <div className="card border-0 shadow-lg pt-2 my-5 w-100 d-flex flex-column">
          <div className="card-body p-4 d-flex flex-column">
            <div className="card-text pt-4 flex-grow-1">
              <h5 className="member-name mb-0 text-center text-primary font-weight-bold">Kuldeep Singh</h5>
              <div className="mb-3 text-center">Director (Data Engineering Expert)</div>
              <div className="text-justify">
                A data engineering veteran with 10+ years of experience in designing robust data pipelines and optimizing data processing systems.
              </div>
            </div>
          </div>
          <div className="card-footer theme-bg-primary border-0 text-center mt-auto">
            <ul className="social-list list-inline mb-0 mx-auto">
            <li className="list-inline-item">
                <a className="text-dark">
                  <i className={`fab fa-linkedin-in fa-fw`}></i>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      {/* Team Member: Santosh Kumar Singh */}
      <div className="col-12 col-md-6 col-lg-6 d-flex">
        <div className="card border-0 shadow-lg pt-2 my-5 w-100 d-flex flex-column">
          <div className="card-body p-4 d-flex flex-column">
            <div className="card-text pt-4 flex-grow-1">
              <h5 className="member-name mb-0 text-center text-primary font-weight-bold">Jitendra Parihar</h5>
              <div className="mb-3 text-center">Head of Intelligent Automation</div>
              <div className="text-justify">
               Leading automation and robotics innovation with over 5 years of expertise.
              </div>
            </div>
          </div>
          <div className="card-footer theme-bg-primary border-0 text-center mt-auto">
            <ul className="social-list list-inline mb-0 mx-auto">
            <li className="list-inline-item">
                <a className="text-dark">
                  <i className={`fab fa-linkedin-in fa-fw`}></i>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      {/* Team Member: Santosh Kumar Singh */}
      <div className="col-12 col-md-6 col-lg-6 d-flex">
        <div className="card border-0 shadow-lg pt-2 my-5 w-100 d-flex flex-column">
          <div className="card-body p-4 d-flex flex-column">
            <div className="card-text pt-4 flex-grow-1">
              <h5 className="member-name mb-0 text-center text-primary font-weight-bold">Rishikant Pandey</h5>
              <div className="mb-3 text-center">Head of Client Relations and Marketing</div>
              <div className="text-justify">
               Proven leader with a 5+ year track record in business growth and customer success.
              </div>
            </div>
          </div>
          <div className="card-footer theme-bg-primary border-0 text-center mt-auto">
            <ul className="social-list list-inline mb-0 mx-auto">
            <li className="list-inline-item">
                <a className="text-dark">
                  <i className={`fab fa-linkedin-in fa-fw`}></i>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>


    </div>
  </div>
</section>

<section class="about about-lists">
  <div class="container">
    <div class="row no-gutters">
    <div class="section-title text-center">
  <h2 className='pt-4'>Recognised Under</h2>
  <p className='text-center'>Our commitment to quality and innovation has been recognized across various industry standards and awards.</p>
</div>


      <div class="col-lg-4 col-md-6 content-item">
        <div class="logo-heading">
          <img src="/assets/img/msme.png" alt="MSME Logo" class="logo-img"/>
          <h4 className='px-2'>MSME</h4>
        </div>
        <p>Recognized under the Ministry of Micro, Small & Medium Enterprises, supporting the growth and development of businesses.</p>
      </div>

      <div class="col-lg-4 col-md-6 content-item">
        <div class="logo-heading">
          <img src="/assets/img/StartUpIndia.jpeg" alt="Startup India Logo" class="logo-img"/>
          <h4 className='px-2'>Startup India</h4>
        </div>
        <p>Empowering startups with resources, mentorship, and funding to fuel the entrepreneurial ecosystem in India.</p>
      </div>

      <div class="col-lg-4 col-md-6 content-item">
        <div class="logo-heading">
          <img src="/assets/img/MakeInIndia.jpeg" alt="Make in India Logo" class="logo-img"/>
          <h4 className='px-2'>Make in India</h4>
        </div>
        <p>Part of the 'Make in India' initiative, promoting innovation and investment to transform India into a global manufacturing hub.</p>
      </div>

      <div class="col-lg-4 col-md-6 content-item">
        <div class="logo-heading">
          <img src="/assets/img/Gem.jpeg" alt="GeM OEM Logo" class="logo-img"/>
          <h4 className='px-2'>GeM as an OEM</h4>
        </div>
        <p>Registered as an Original Equipment Manufacturer (OEM) on the Government e-Marketplace, providing quality products and services.</p>
      </div>

      <div class="col-lg-4 col-md-6 content-item">
        <div class="logo-heading">
          <img src="/assets/img/iso.jpeg" alt="ISO Logo" class="logo-img"/>
          <h4 className='px-2'>ISO Certified</h4>
        </div>
        <p>ISO certification ensures adherence to international standards of quality, safety, and efficiency in products and services.</p>
      </div>

      <div class="col-lg-4 col-md-6 content-item">
        <div class="logo-heading">
          <h4>NIT Uttarakhand, IIT Roorkee & IIT Kanpur</h4>
        </div>
        <p>Collaborating with premier institutions like NIT Uttarakhand, IIT Roorkee & IIT Kanpur to drive innovation, research, and development.</p>
      </div>
    </div>
  </div>
</section>



<section id="faq" className="faq section">
      <div className="container section-title">
        <h2>Frequently Asked Questions</h2>
        <p>Learn more about Advisions R&D and how we transform industries.</p>
      </div>
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="faq-container">
              {faqs.map((faq, index) => (
                <div
                  className={`faq-item ${activeIndex === index ? "faq-active" : ""}`}
                  key={index}
                >
                  <h3 onClick={() => toggleFAQ(index)}>{faq.question}</h3>
                  <div
                    className="faq-content"
                    style={{
                      display: activeIndex === index ? "block" : "none",
                    }}
                  >
                    <p>{faq.answer}</p>
                  </div>
                  <i
                    className={`faq-toggle bi ${
                      activeIndex === index
                        ? "bi-chevron-down"
                        : "bi-chevron-right"
                    }`}
                    onClick={() => toggleFAQ(index)}
                  ></i>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>







      <Footer />
    </>
  );
}
